import './ContactUsButton.scss'


export const ContactUsButton = () => {
  return (
 
    <button  className="contact__button">Contact Us </button>

   );
};

