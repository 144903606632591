import './Navbar.scss'
import { Link, NavLink } from 'react-router-dom';
import Logo from '../../assets/images/logo.png'
import ContactUsButton from '../ContactButton';
import { useEffect, useRef, useState } from 'react';



export const Navbar = () => {
   const [active, setActive] = useState(false)
   const refOne = useRef(null)

   const handleClickOutside = (e) => {
     
      if(active) {
         if(!refOne.current.contains(e.target)) {
            console.log(refOne.current)
            console.log("hello")
            setActive(false)
         }
      }
      
   }

   useEffect(()=> {
      document.addEventListener("click", handleClickOutside) 

      return (()=>{
         document.addEventListener('click', handleClickOutside)
      })
   })
   return (
      <header className="header" id="header" ref={refOne}>

         <div className="container h-100 d-flex align-center justify-between">
            <Link to="/" className="header__logo">
               <img src={Logo} alt="logo" />
            </Link>


            <nav className="header__nav">
               <NavLink to="/" className="header__link canHover">Home</NavLink>
               <NavLink to="/about" className="header__link canHover">About</NavLink>
               <NavLink to="/services" className="header__link canHover">Services</NavLink>
               <NavLink to="/quote" className="header__link canHover">Get a quote</NavLink>
               <NavLink to="tel:+14702607325" className="header__link"><button className="header__button">+1 470-260-7325</button></NavLink>
               <NavLink to="/contact" className="header__link"><ContactUsButton></ContactUsButton></NavLink>
            </nav>


            <div className={'header__burger burger ' + (active ? "active" : "")}>
               <div className='burger__divforabsolute'>
                  <div onClick={() => { setActive(prev => !prev) }} className="burger__click"></div>
               </div>

            </div>



         </div>
         {active &&

            <div className='burger__menu'>
               <nav className="burger__nav d-flex flex-column align-center justify-between">
                  <NavLink to="/" onClick={() => { setActive(prev => !prev) }} className="burger__link canHover">Home</NavLink>
                  <NavLink to="/about" onClick={() => { setActive(prev => !prev) }} className="burger__link canHover">About</NavLink>
                  <NavLink to="/services" onClick={() => { setActive(prev => !prev) }} className="burger__link canHover">Services</NavLink>
                  <NavLink to="/quote" onClick={() => { setActive(prev => !prev) }} className="burger__link canHover">Get a quote</NavLink>
                  <NavLink to="/contact" onClick={() => { setActive(prev => !prev) }} className="burger__link canHover">Contact Us</NavLink>
               </nav>
            </div>}
      </header>
   );
};